import React from 'react';

export default class App extends React.Component {
    constructor(props){
	super(props);
	this.state = {
	    content: null
	};
    }
    
    componentDidMount() {
	let site = window.SITE;

	if (site === "advocast"){
	    import('./Advocast/Advocast').then(
		({Advocast}) => {
		    this.setState({
			content:
			(
			    <Advocast />
			)
		    });
		}
	    )
	} else if (site === "school_finder"){
	    import('./SchoolFinder/SchoolFinder').then(
		({SchoolFinder}) => {
		    this.setState({
			content:
			(
			    <SchoolFinder />
			)
		    });
		}
	    )

	} else if (site === "david_experiment"){
	    import('./DavidExperiment/DavidExperiment').then(
		({DavidExperiment}) => {
		    this.setState({
			content:
			(
			    <DavidExperiment />
			)
		    });
		}
	    )
	    
	} else if (site === "david_consulting"){
	    import('./DavidConsulting/DavidConsulting').then(
		({DavidConsulting}) => {
		    this.setState({
			content:
			(
			    <DavidConsulting />
			)
		    });
		}
	    )
	    
	} else if (site === "keepsake_digital"){
      import('./KeepsakeDigital/KeepsakeDigital').then(
      ({KeepsakeDigital}) => {
        this.setState({
        content:
        (
          <KeepsakeDigital />
        )
        });
      }
      )
  } else {
	    let hostname = window.location.hostname;
	    this.setState({content: (<div>Unknown site {hostname}</div>)});
	}
    }

    render(){
	return this.state.content;
    }
}

