import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import App from './App';
import { createBrowserRouter, RouterProvider } from "react-router-dom";


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />
  },
]);
<RouterProvider router={router} />

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <React.StrictMode>
	<App />
    </React.StrictMode>
);

